import React from "react";

import { getEntityUrl, getTranslatedEntityLink } from "../util";

import { Link, useStaticQuery, graphql } from "gatsby";

const LanguageMenu = ({ locales, classes, entity, curLocale, defaultLocale, handleClose }) => {
    const { allStrapiAppSettings: settings } = useStaticQuery(staticQuery);



    return <>
        {(locales?.length > 1 || settings.nodes[0]?.Topbar?.pseudoLanguages > 0) &&
            <div key="langList">
                <div className={classes.item} key="empty">&nbsp;</div>
                {
                    locales.map((locale) => {
                        if (locale.code === curLocale) return <React.Fragment key={locale.code} />
                        const link = getTranslatedEntityLink({ locale: locale, defaultLocale, entity });
                        return <Link key={locale.code} className={classes.item} idName="languageLink" onClick={handleClose} to={link}>
                            {locale.name}
                        </Link>
                    })
                }
                {settings.nodes[0]?.Topbar?.pseudoLanguages?.map(item => {
                    const link = getEntityUrl({ defaultLocale, entity: item });

                    return <Link key={item.identifier} className={classes.item} onClick={handleClose} to={link}>
                            {item.title}
                        </Link>
                })}
            </div>
        }
    </>
}

export default LanguageMenu;


const staticQuery = graphql`
    query {
        allStrapiAppSettings {
            nodes {
                Topbar {
                    pseudoLanguages  {
                        identifier
                        title
                        locale
                    }
                }
            }
        }
    }
`;